import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import * as styles from './Image.module.scss';

const Image = ({ data }) => {
	return (
		<>
			{data.imageMobile?.data || data.imageDesktop?.data ? (
				<Container>
					<Row>
						<Col>
							{data.imageMobile?.data ? (
								<figure className={`${styles.figure} mb-0 d-md-none`}>
									<img
										alt={data.imageMobile.data.attributes.alternativeText}
										src={data.imageMobile.data.attributes.url}
										className="img-fluid w-100"
									/>
								</figure>
							) : ``}
							{data.imageDesktop?.data ? (
								<figure className={`${styles.figure} mb-0 d-none d-md-block`}>
									<img
										alt={data.imageDesktop.data.attributes.alternativeText}
										src={data.imageDesktop.data.attributes.url}
										className="img-fluid w-100"
									/>
								</figure>
							) : ``}
						</Col>
					</Row>
				</Container>
			) : ``}
		</>
	)
}

export default Image;
