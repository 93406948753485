import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as styles from "./Listings.module.scss";
import image2x from "../../images/careers-listing@2x.png";

const Listings = ({ jobs }) => {
  if (jobs.length > 0) {
    return (
      <div className={styles.wrapper}>
        <div
          className={styles.background}
          style={{ backgroundImage: `url(${image2x})` }}
        />
        <Container>
          <Row>
            <Col>
              <h2 className="text-center">Current Vacancies</h2>
            </Col>
          </Row>
          {jobs.map((job, index) => {
            const date = new Date(job.attributes.startDate);
            const dateOptions = {
              day: `numeric`,
              month: `long`,
              year: `numeric`,
            };

            return (
              <Row key={index}>
                <Col>
                  <div className={styles.job}>
                    <Row className="justify-content-lg-between">
                      <Col xs={12} lg={8}>
                        <h4 className={styles.jobTitle}>
                          {job.attributes.title}
                        </h4>
                        <p className={styles.jobDescription}>
                          {job.attributes.description}
                        </p>
                        <Row>
                          <Col xs="auto">
                            <p className={styles.jobInfoTitle}>Salary:</p>
                            <p className={styles.jobInfoValue}>
                              {job.attributes.salary}
                            </p>
                          </Col>
                          <Col xs="auto">
                            <p className={styles.jobInfoTitle}>Start date:</p>
                            <p className={styles.jobInfoValue}>
                              {date.toLocaleString("en-gb", dateOptions)}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} lg={`auto`}>
                        <div className={styles.buttons}>
                          <a
                            className="btn btn-lg btn-secondary"
                            href={`/careers/${job.attributes.slug}`}
                          >
                            Learn more
                          </a>
                            {job.attributes.applicationLink ? (
                                <a
                                    className="btn btn-lg btn-primary mt-3 mt-lg-0 ms-lg-3"
                                    href={job.attributes.applicationLink}
                                    aria-label="Apply now">
                                    Apply now
                                </a>
                            ) : ``}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            );
          })}
        </Container>
      </div>
    );
  }
};

export default Listings;
