import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as styles from "./Overview.module.scss";

const Overview = ({ title, items }) => {
  let incentives = [];

  if (
    items.data &&
    typeof items.data === `object` &&
    Object.keys(items.data).length > 0
  ) {
    incentives = Object.keys(items.data).map(k => items.data[k]);
  }

  return (
    <div className={styles.wrapper}>
      <Container>
        <Row>
          <Col>
            <h2 className="text-center">{title}</h2>
          </Col>
        </Row>
        {incentives.length > 0 ? (
          <Row>
            {incentives.map((incentive, index) => {
              return (
                <Col xs={12} md={4} key={index}>
                  <div className={styles.item}>
                    {incentive.image.data ? (
                        <figure className={styles.itemFigure}>
                          <img
                              className="img-fluid"
                              alt={incentive.image.data.attributes.alternativeText}
                              src={incentive.image.data.attributes.url}
                          />
                        </figure>
                    ) : ``}
                    <h3 className={styles.itemTitle}>{incentive.title}</h3>
                    <p className={styles.itemDescription}>
                      {incentive.description}
                    </p>
                  </div>
                </Col>
              );
            })}
          </Row>
        ) : null}
      </Container>
    </div>
  );
};

export default Overview;
