import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import * as styles from './Intro.module.scss';

const Intro = ({ title, paragraph }) => {
	return (
		<div className={styles.wrapper}>
			<Container>
				<Row className="justify-content-center">
					<Col lg={5}>
						<h2 className={styles.title}>{title}</h2>
					</Col>
					<Col lg={5}>
						<ReactMarkdown
							children={paragraph}
							className={styles.text}
							rehypePlugins={[rehypeRaw]}
							remarkPlugins={[remarkGfm]}
						/>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default Intro;
