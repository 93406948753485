// extracted by mini-css-extract-plugin
export var background = "Listings-module--background--GltBP";
export var black = "#060b12";
export var btn = "Listings-module--btn--ZL5SN";
export var buttons = "Listings-module--buttons--6PYt1";
export var job = "Listings-module--job--G3pd5";
export var jobDescription = "Listings-module--job-description--ysRjB";
export var jobInfoTitle = "Listings-module--job-info-title--TLFkw";
export var jobInfoValue = "Listings-module--job-info-value--xFq8r";
export var jobTitle = "Listings-module--job-title--cKrc3";
export var navy = "#0c1523";
export var paleYellow = "#fcffeb";
export var wrapper = "Listings-module--wrapper--S8iTM";
export var yellow = "#f2ff9a";