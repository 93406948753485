import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/Layout';
import Hero from '../components/hero/Hero';
import Image from '../components/careers-landing/Image';
import Intro from '../components/careers-landing/Intro';
import Listings from '../components/careers-landing/Listings';
import Overview from '../components/careers-landing/Overview';
import '../style/main.scss';

const CareersLanding = ({ pageContext: { careersLandingPage }, reporter }) => {
	const metaTitle = ["Ivo Digital", careersLandingPage.seo?.title].filter(Boolean).join(" - ");
	const metaDescription = careersLandingPage.seo?.description || "";
	const metaImage = careersLandingPage.seo?.imageUrl || "";
	const url = typeof window !== `undefined` ? window.location.href : ``;

	return (
		<Layout logoColour="yellow">
			<Helmet htmlAttributes={{ lang: `en` }}>
				<meta charSet="utf-8"/>
				<title>{metaTitle}</title>
				<link rel="canonical" href={url}/>
				<link rel={`description`} content={metaDescription}/>
				<meta property="og:title" content={metaTitle}/>
				<meta property="og:type" content="website"/>
				<meta property="og:url" content={url}/>
				<meta property="og:description" content={metaDescription}/>
				<meta property="og:image" content={metaImage}/>
				<meta property="og:site_name" content={metaTitle}/>
			</Helmet>
			<Hero
				title={careersLandingPage.heroTitle}
				subtitle={careersLandingPage.heroSubtitle}
			/>
			<Image data={careersLandingPage} />
			<Intro
				title={careersLandingPage.introTitle}
				paragraph={careersLandingPage.introParagraph}
			/>
			<Listings jobs={careersLandingPage.careers.data} />
			<Overview
				title={careersLandingPage.overviewTitle}
				items={careersLandingPage.overviewItems}
			/>
		</Layout>
	);
};

export default CareersLanding;
